const middleware = {}

middleware['cache-control-private'] = require('../src/middleware/cache-control-private.js')
middleware['cache-control-private'] = middleware['cache-control-private'].default || middleware['cache-control-private']

middleware['cache-control-public'] = require('../src/middleware/cache-control-public.js')
middleware['cache-control-public'] = middleware['cache-control-public'].default || middleware['cache-control-public']

middleware['l10n'] = require('../src/middleware/l10n.js')
middleware['l10n'] = middleware['l10n'].default || middleware['l10n']

middleware['legacy/index'] = require('../src/middleware/legacy/index.js')
middleware['legacy/index'] = middleware['legacy/index'].default || middleware['legacy/index']

middleware['legacy/rules/entity'] = require('../src/middleware/legacy/rules/entity.js')
middleware['legacy/rules/entity'] = middleware['legacy/rules/entity'].default || middleware['legacy/rules/entity']

middleware['legacy/rules/exhibitions'] = require('../src/middleware/legacy/rules/exhibitions.js')
middleware['legacy/rules/exhibitions'] = middleware['legacy/rules/exhibitions'].default || middleware['legacy/rules/exhibitions']

middleware['legacy/rules/explore'] = require('../src/middleware/legacy/rules/explore.js')
middleware['legacy/rules/explore'] = middleware['legacy/rules/explore'].default || middleware['legacy/rules/explore']

middleware['legacy/rules/galleries'] = require('../src/middleware/legacy/rules/galleries.js')
middleware['legacy/rules/galleries'] = middleware['legacy/rules/galleries'].default || middleware['legacy/rules/galleries']

middleware['legacy/rules/html'] = require('../src/middleware/legacy/rules/html.js')
middleware['legacy/rules/html'] = middleware['legacy/rules/html'].default || middleware['legacy/rules/html']

middleware['legacy/rules/portal'] = require('../src/middleware/legacy/rules/portal.js')
middleware['legacy/rules/portal'] = middleware['legacy/rules/portal'].default || middleware['legacy/rules/portal']

middleware['legacy/rules/record'] = require('../src/middleware/legacy/rules/record.js')
middleware['legacy/rules/record'] = middleware['legacy/rules/record'].default || middleware['legacy/rules/record']

middleware['legacy/rules/search'] = require('../src/middleware/legacy/rules/search.js')
middleware['legacy/rules/search'] = middleware['legacy/rules/search'].default || middleware['legacy/rules/search']

middleware['legacy/rules/static-pages'] = require('../src/middleware/legacy/rules/static-pages.js')
middleware['legacy/rules/static-pages'] = middleware['legacy/rules/static-pages'].default || middleware['legacy/rules/static-pages']

middleware['no-ssr-cookies'] = require('../src/middleware/no-ssr-cookies.js')
middleware['no-ssr-cookies'] = middleware['no-ssr-cookies'].default || middleware['no-ssr-cookies']

middleware['trailing-slash'] = require('../src/middleware/trailing-slash.js')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

export default middleware
